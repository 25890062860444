import debounce from 'debounce';
import ListenerManager from './listenerManager';

let listenerBoss = new ListenerManager();
let initAttribute = 'data-video-ready';

export default {
	init(selector = '[data-video-cover-holder]') {
		let holderEls = document.querySelectorAll(selector);
		if (holderEls.length) {
			holderEls.forEach(holderEl => {
				if (!holderEl.hasAttribute(initAttribute)) {

					//add the attribute
					holderEl.setAttribute(initAttribute, 'y');

					let videoEl = holderEl.querySelector('video');
					let videoRatio = parseInt(holderEl.getAttribute('data-width') || 1, 10) / parseInt(holderEl.getAttribute('data-height') || 1, 10);

					if (videoEl) {
						videoEl.style.height = 'auto';
						videoEl.style.minHeight = 'auto';

						const resizeVideo = () => {
							//adjust the video width if necessary
							if (videoEl) {
								videoEl.style.width = (holderEl.offsetHeight*videoRatio)+'px';
							}
						};

						//watch
						['DOMContentLoaded', 'load', 'resize'].forEach(function(eventName) {
							listenerBoss.register(window, eventName, debounce(resizeVideo, 10, false));
						});
						resizeVideo();
					}
				}
			});
		}
	},
	destroy() {
		listenerBoss.removeAll();
	}
}
