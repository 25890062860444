import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();

/**!
 * Featured Clients
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2019
 */
export default {
	init(selector = '[data-clients]', toggleAttribute = 'data-clients-toggle') {
		let els = document.querySelectorAll(selector);
		if (els.length) {
			els.forEach(el => {
				let toggles = el.querySelectorAll('['+toggleAttribute+']');
				if (toggles) {
					toggles.forEach(toggle => {
						listenerBoss.register(toggle, 'click', function (){
							let toggleType = toggle.getAttribute(toggleAttribute);
							if (toggleType === 'grid') {
								el.classList.add('clients--grid');
								el.classList.remove('clients--list');
							} else {
								el.classList.add('clients--list');
								el.classList.remove('clients--grid');
							}
						})
					});
				}
			});
		}
	},
	destroy() {
		listenerBoss.removeAll();
	}
};
