import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();

/**!
 * Featured Clients
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2019
 */
export default {
	init(selector = '[data-featured-client]', expandedClass = 'featuredClient--expanded') {
		let els = document.querySelectorAll(selector);
		if (els.length) {
			els.forEach(el => {
				//more button
				let moreButton = el.querySelector('[data-featured-client-more]');
				if (moreButton) {
					listenerBoss.register(moreButton,'click', function(){
						el.classList.add(expandedClass);
					});
				}

				//less button
				let lessButton = el.querySelector('[data-featured-client-less]');
				if (lessButton) {
					listenerBoss.register(lessButton,'click', function(){
						el.classList.remove(expandedClass);
					});
				}
			});
		}
	},
	destroy() {
		listenerBoss.removeAll();
	}
};
