import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();


/**!
 * Featured Clients
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2019
 */
export default {
	_linkSelectedClass: 'selected',
	_removeClassFromLinks: function(links) {
		links.forEach(link => {
			link.classList.remove(this._linkSelectedClass);
		});
	},
	_selectSlide : function(slider, selectedLink, links) {
		this._removeClassFromLinks(links);
		let index = selectedLink.getAttribute('data-switcher-slide');
		index = parseInt(index, 10);
		slider.select(index);
		selectedLink.classList.add(this._linkSelectedClass);
	},
	init(selector = '[data-switcher]', linkSelectedClass = 'selected') {
		this._linkSelectedClass = linkSelectedClass;

		let els = document.querySelectorAll(selector);
		if (els.length) {
			els.forEach(el => {
				//get the index
				let slideIndex = el.getAttribute('data-switcher');
				let sliderId = '#switcherSlider-'+slideIndex;
				let sliderEl = document.querySelector(sliderId);
				if (sliderEl) {
					let selectedIndex = null
					let sliderReady = data => {
						if (data.el === sliderEl) {
							let slider = data.slider;
							if (slider) {
								//get the items
								let links = el.querySelectorAll('[data-switcher-slide]');
								if (links.length) {

									//select the first slide/link
									this._selectSlide(slider, links[0], links)

									links.forEach((link, ind) => {
										// link mouse enter
										listenerBoss.register(link,'mouseenter', () =>  {
											// select slide
											this._selectSlide(slider, link, links)

											// pause the player
											slider.pausePlayer()
										})

										//link mouse leave
										listenerBoss.register(link,'mouseleave', () =>  {
											// unpause the player
											slider.unpausePlayer()
										})

										//slider select
										slider.on('select', index => {
											if (selectedIndex !== index) {
												selectedIndex = index
												let link = el.querySelector(`[data-switcher-slide="${index}"]`);
												if (link) {
													this._selectSlide(slider, link, links)
												}
											}
										});
									});
								}
							}
						}
					};
					window.sitePubSub.subscribe('flickityReady', sliderReady);
				}
			});
		}
	},
	destroy() {
		window.sitePubSub.unsubscribe('flickityReady');
		listenerBoss.removeAll();
	}
};
