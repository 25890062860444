import isIE11 from './Utils/isIE11'

// ---------- Polyfills ----------
import './Utils/makeEventListenersPassive'
import 'element-qsa-scope';
import 'focus-visible/dist/focus-visible';
import 'svgxuse';
import 'wicg-inert/dist/inert';
import 'focus-within';

// ---------- global PubSub ----------
//see https://github.com/georapbox/PubSub
import PubSub from 'PubSub';
window.sitePubSub = new PubSub();

// ---------- js class ----------
import './Components/jsClass';

// ---------- ie11 class ----------
import './Components/ie11Class';

// ---------- lazysizes ----------
import lazySizes from 'lazysizes';
//import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
Object.assign(lazySizes.cfg, {loadMode:1});

// ---------- global elements and classes ----------
const holderEl = document.querySelector('.holder');
const navEl = document.querySelector('.header-menu');
const navToggleEl = document.querySelector('.header-toggle');
let previouslyActiveEl = false;
const navOpenClass = 'menu--open';

// ---------- mobile menu ----------
import MobileMenu from './Components/mobileMenu';
const nav = new MobileMenu({
	openClass: 'menu--isOpen',
	closeClass: 'menu--isClosed',
	toggleSelector: '[data-nav-toggle]',
	onOpen() {
		if (navEl) {
			//make the nav el not inert
			navEl.inert = false;

			//add the nav open class (used for transitions)
			navEl.classList.add(navOpenClass);
		}

		//set the previously active item
		previouslyActiveEl = document.activeElement;

		//update the toggles for accessibility
		if (this.mobileMenuToggles.length) {
			this.mobileMenuToggles.forEach(mobileMenuToggle => {
				//set the aria expanded attributes
				mobileMenuToggle.setAttribute('aria-expanded', true);

				//set the aria label attribute
				mobileMenuToggle.setAttribute('aria-label', 'Close menu');
			});
		}

		//focus the nav logo
		if (navToggleEl) {
			navToggleEl.focus();
		}
	},
	onClose() {
		if (navEl) {
			//make the nav el not inert
			navEl.inert = true;

			//remove the nav open class (used for transitions)
			navEl.classList.remove(navOpenClass);
		}

		//update the toggles for accessibility
		if (this.mobileMenuToggles.length) {
			this.mobileMenuToggles.forEach(mobileMenuToggle => {
				//set the aria expanded attributes
				mobileMenuToggle.setAttribute('aria-expanded', false);

				//set the aria label attribute
				mobileMenuToggle.setAttribute('aria-label', 'Open Menu');
			});
		}

		//attempt to restore focus
		if (previouslyActiveEl) {
			previouslyActiveEl.focus();
		}
	}
});
nav.close();

// ---------- svg sprite ----------
import './Components/svgSprite';

// ---------- responsive videos ----------
import responsiveVideos from './Components/responsiveVideos';

// ---------- hidden videos ----------
import hiddenVideos from './Components/hideVideosUntilPlay';

// ---------- lightbox ----------
import MediaLightbox from './Components/lightbox';
let lightbox = new MediaLightbox({
	onOpen() {
		if (holderEl) {
			holderEl.inert = true;
		}
	},
	onClose() {
		if (holderEl) {
			holderEl.inert = false;
		}
	}
});
window.lightbox = lightbox;

// ---------- scrollbar compensation ----------
import scrollbarCompensation from './Components/scrollbarCompensation';
scrollbarCompensation();

// ---------- mobile vh fix ----------
import mobileVh from './Components/mobileVh';
mobileVh();

// ---------- top bar ----------
import topbar from 'topbar';
topbar.config({
	barColors: {0:'rgba(238, 59, 51, 1)', 1:'rgba(238, 59, 51, 1)'},
	shadowBlur: 0,
	shadowColor  : 'rgba(0, 0, 0, 0)'
});

// ---------- video cover (for background video) ----------
import videoCover from './Components/videoCover';

// ---------- featuredClients ----------
import featuredClients from './Components/featuredClients';

// ---------- clients ----------
import clients from './Components/clients';

// ---------- switcher ----------
import switcher from './Components/switcher';

// ---------- nav active state updater ----------
import updateNav from './Components/updateNav';

// ---------- on page load ----------
const onPageLoad = function(isInitialLoad = false) {
	// ---------- nav ----------
	updateNav();

	// ---------- csrf tokens ----------
	window.csrfTokenName = document.querySelector('meta[name="csrf-name"]').getAttribute('content');
	window.csrfTokenValue = document.querySelector('meta[name="csrf-value"]').getAttribute('content');

	// ---------- switcher ----------
	//note: this should happen before the sliders
	if (!isInitialLoad) {
		switcher.destroy();
	}
	switcher.init();

	// ---------- sliders ----------
	let sliders = document.querySelectorAll('[data-flickity-slider]')
	if (sliders.length) {
		import(/* webpackChunkName: "sliders", webpackPreload: true */ './Components/flickitySliders').then(
			({ default: flickitySliders }) => {
				flickitySliders.init({ slideSelector: '.slide' })
			},
		)
	}

	// ---------- video cover ----------
	if (!isInitialLoad) {
		videoCover.destroy();
	}
	videoCover.init();

	// ---------- featuredClients ----------
	if (!isInitialLoad) {
		featuredClients.destroy();
	}
	featuredClients.init();

	// ---------- clients ----------
	if (!isInitialLoad) {
		clients.destroy();
	}
	clients.init();

	// ---------- map ----------
	let maps = document.querySelectorAll('[data-map]')
	if (maps.length) {
		import(/* webpackChunkName: "simpleMaps", webpackPrefetch: true */ './Components/simpleMaps').then(
			({ default: simpleMaps }) => {
				simpleMaps.init()
			},
		)
	}

	// ---------- responsive videos ----------
	if (!isInitialLoad) {
		responsiveVideos.destroy();
	}
	responsiveVideos.init();

	// ---------- hidden videos ----------
	if (!isInitialLoad) {
		hiddenVideos.destroy();
	}
	hiddenVideos.init();

	// ---------- search ----------
	let searchApps = document.querySelectorAll('[data-search-app]')
	if (searchApps.length) {
		import(/* webpackChunkName: "searchApps", webpackPrefetch: true */ './Components/searchApps').then(
			({ default: searchApps }) => {
				searchApps.init()
			},
		)
	}

	// ---------- lightbox ----------
	lightbox.init();

	// ---------- focus h1 ----------
	if (!isInitialLoad) {
		//attempt to focus the page's h1
		let h1El = document.querySelector('h1');
		if (h1El) {
			h1El.tabIndex = -1;
			h1El.focus();
		}
	}
};
onPageLoad(true);

document.addEventListener('pjax:success', () => {
	onPageLoad(false);
});
document.addEventListener('pjax:error', e => {
	console.log('pjax error', e)
	if (e.hasOwnProperty('request') && (e.request.status === 301 || e.request.status === 302)) {
		window.location.replace(e.request.responseURL)
	}
})
document.addEventListener('pjax:send', e => {
	// ---------- nav ----------
	nav.close();

	// ---------- lightbox ----------
	lightbox.close();

	// ---------- topbar ----------
	topbar.show();
});

document.addEventListener('pjax:complete', topbar.hide);

// ---------- pjax ----------
if (!isIE11) {
	import(/*webpackChunkName: "pjax"*/ 'pjax').then(({ default: Pjax }) => {
		new Pjax({
			elements:
				'a[href]:not(.no-pjax):not([href*=".gif"]):not([href*=".jpg"]):not([href*=".png"]):not([href*=".pdf"]):not([href*=".json"]), form[method="get"]',
			selectors: [
				'head > title',
				'meta[name="description"]',
				'meta[name="csrf-name"]',
				'meta[name="csrf-value"]',
				'.main',
			],
			cacheBust: false,
		})
	})
}
